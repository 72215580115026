export default {
  palette: {
    primary: '#1473e6',
    blue: {
      one: '#F7FAFF',
    },
    secondary: '#64D0FF',
    background: {
      primary: '#FFFFFF',
      secondary: '#0E7EF9',
      gray: '#F5F5F5',
    },
    text: {
      one: '#191919',
      two: '#707070',
      three: '#82B5A5',
      four: '#757575',
    }
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
