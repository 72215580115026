import React from 'react';
import Hero from './Hero';
import Product from './Product';
import Solution from './Solution';
import Why from './Why';
import Schedule from './Schedule';
import Footer from './/Footer';

const Home = props => {
  return (
    <>
      <Hero />
      <Product />
      <Solution />
      <Why />
      <Schedule />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
