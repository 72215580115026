import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import chatImage from 'resources/img/readyToChat.png';
import personalisedScreenImage from 'resources/img/personalisedScreen.png';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const Grid = styled(MuiGrid)`
        justify-content: center;
`;

const LookAlikeContainer = styled(animated.div)`
    padding: 1rem;
    text-align: center;
    vertical-align:middle;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin-top: 1rem;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    } 
`;

const SectionHeader = styled.h1`

  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto 92px;
  padding-bottom: 12px;
  max-width: 700px;
  text-align: center;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
    margin-bottom: 2rem;
  }
`;




const LookAlikeWrapper = styled.div`
  overflow: hidden;
  padding: 3rem 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const LookAlikeContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}` }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const LookAlikeHeader = styled.h2`
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 1rem;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
    max-width: unset;
    padding-top: 1rem;
  }
`;

const LookAlikeDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    padding-bottom: 31px;
    font-weight: normal;
    text-align: left;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
  }
`;

const LookAlikeAnimatedImg = styled(animated.img)`
  width: 510px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    width: calc(414px - 2rem);
  }
`;

const LookAlike = props => {
    const [isContentVisible, setContentIsVisible] = React.useState(false);

    const leftCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
    });
    const rightCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
    });


    return (
            <Waypoint
                scrollableAncestor={window}
                topOffset="10%"
                bottomOffset="35%"
                onEnter={() => setContentIsVisible(true)}
                onLeave={() => setContentIsVisible(false)}
            >

                <LookAlikeContainer>
                    <SectionHeader>What does that look like?</SectionHeader>
                    <LookAlikeWrapper>
                        <Container>
                            <Grid container>

                                <Grid lg={6}>
                                    <LookAlikeAnimatedImg style={leftCardStyleProps} src={chatImage} alt="LookAlike" />
                                </Grid>
                                <Grid lg={6}>
                                    <LookAlikeContentWrapper paddingRight="3rem" style={rightCardStyleProps}>
                                        <div>
                                            <LookAlikeHeader>
                                                Get ready to chat.
                                    </LookAlikeHeader>
                                            <LookAlikeDesc>
                                                You will be notified when a significantly engaged prospect is live browsing on your website.
                                                <br />
                                                Jump in and get ready to initiate a conversation right then and there before they leave.
                                    </LookAlikeDesc>
                                        </div>
                                    </LookAlikeContentWrapper>
                                </Grid>
                            </Grid>
                        </Container>
                    </LookAlikeWrapper>
                    <LookAlikeWrapper>
                        <Container>
                            <Grid container>

                                
                                <Grid lg={6}>
                                    <LookAlikeContentWrapper paddingLeft="3rem" style={leftCardStyleProps}>
                                        <div>
                                            <LookAlikeHeader>
                                                Personalized intel on the same screen.
                                    </LookAlikeHeader>
                                            <LookAlikeDesc>
                                                Striking up a conversation with the prospect in real-time can be difficult. 
                                                <br />
                                                <br />
                                                So while you’re in our messaging platform, you can see Insent’s Account View on the same screen. View the prospect’s latest engagement metrics, campaign membership data, and account-level insights to quickly spin up a conversation starter.
                                    </LookAlikeDesc>
                                        </div>
                                    </LookAlikeContentWrapper>
                                </Grid>
                                <Grid lg={6}>
                                    <LookAlikeAnimatedImg style={rightCardStyleProps} src={personalisedScreenImage} alt="LookAlike" />
                                </Grid>
                            </Grid>
                        </Container>
                    </LookAlikeWrapper>
                </LookAlikeContainer>
            </Waypoint>
    );
};

LookAlike.propTypes = {};

export default LookAlike;