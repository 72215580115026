import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const Button = styled(animated.button)`
  z-index: 1;
  border-radius: 24px;
  height: 37px;
  padding: 0px 26px;
  font-size: 12px;
  background-color: #0a7ef9;
  border-color: #0a7ef9;
  border-width: 0;
  color: white;
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  ${p => p.width && `width: ${p.width}` }
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: 0;
  }

  ${p => {
    const variant = p.variant;

    if (variant === 'outlined') {
      return css`
        border-width: 1px;
        background-color: transparent;
        color: #0a7ef9;
      `;
    } else if (variant === 'link') {
      return css`
        border: none;
        background-color: transparent;
        color: #0a7ef9;
      `;
    }
  }}
`;

Button.displayName = 'Button';

Button.propTypes = {
  // variant: PropTypes.oneOf(['contained', 'outlined']),
};

export default Button;
