import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import spring from 'resources/img/spring.png';
import Text from 'ui/components/Text';
import botBox from 'resources/img/botBox.svg';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`; 

const ChatBotContainer = styled.div`
    opacity: 1;
`;

const SectionImage = styled(animated.img)`
  max-width: 338px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`;

const DifferentiatorContainer = styled.div`
  overflow: hidden;
  padding: 120px 2rem;


  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 4rem 0;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 80px 0;

    button {
      text-align: center;
    }
  }
`;


const BotTakeoverContainer = styled.div`
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 940px;
  margin: auto;
  padding: 120px 2rem 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }
`;

const BotTakeoverContent = styled.div`
  opacity: 1;
  max-width: 435px;
`;

const NotesAnimatedImg = styled(animated.img)`
    object-fit: contain;
    display: block;
    height: 311px;
    width: 274px;
    float: right;
    margin-top: 1rem;
    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        display: none;
    }
`;

const DifferentiatorContentWrapper = styled.div`
    max-width: 800px;
    float: left;
`;

const ChatBot = props => {
    const [isContentVisible, setContentIsVisible] = React.useState(false);

    const leftCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
    });
    const rightCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
    });

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
            onEnter={() => setContentIsVisible(true)}
            onLeave={() => setContentIsVisible(false)}
        >
            <ChatBotContainer>
                <BotTakeoverContainer>
                    <SectionImage src={botBox} />
                    <BotTakeoverContent>
                        <Text fontSize="40px" fontWeight={400} color="black" smFontSize="30px">
                            Even your most engaged buyershave to navigate a <span>high-friction chatbot.</span>
                        </Text>
                    </BotTakeoverContent>
                </BotTakeoverContainer>
                <DifferentiatorContainer>
                    <Container>
                        <DifferentiatorContentWrapper>
                          <Text fontSize="20px" smTextAlign="center">ALSO IMPORTANT TO NOTE:</Text>
                          <Text fontWeight={700} fontSize="40px" paddingTop="10px" paddingBottom="30px" smFontSize="30px">B2B buying cycles are way more complex</Text>
                          <Text fontSize="20px">
                            In fact, 77% B2B buyers reported to Gartner that their latest purchase was difficult or complex.
                            <br />
                            <br />
                            Given the complexity of the buying environment today, your prospects and customers want information they can trust and easily gauge to help them simplify the purchasing process.
                            <br />
                            <br />
                            While a bot can help you book meetings and acquire email addresses, it’s not what your prospects can trust to build relationships, and buy expensive software from—because that’s you.
                          </Text>
                        </DifferentiatorContentWrapper>
                        <NotesAnimatedImg src={spring} />
                    </Container>
                </DifferentiatorContainer>
            </ChatBotContainer>
        </Waypoint>
    );
};

ChatBot.propTypes = {};

export default ChatBot;
