import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

const SolutionContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  background-color: rgba(245, 245, 245, 0.5);

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const SolutionPreHeader = styled.div`
  color:#191919;
  // border:1px solid red;
  text-align:center;
  font-weight:100;
  font-size:34px;
  max-width:700px;
  margin:auto;
  margin-bottom:80px;
  font-stretch: normal;
  line-height: 1.2;

  span {
    // font-style: italic;
    font-weight: 700;
    color: #0a7ef9;
  }
`;

const SolutionCard = styled(({ bgColor, ...others }) => <animated.div {...others} />)`
  background-color: ${p => p.bgColor || 'white'};
  padding: ${p => p.padding || '95px 74px'};
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 95px 74px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 17px;
  }
`;

const SolutionHeader = styled.h2`
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #191919;
`;

const SolutionDesc = styled.p`
  width: 272px;
  height: 40px;
  font-family: ProductSans;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #191919;
`;

const SolutionListTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.22;
  color: #757575;
  margin-bottom: 8px;
`;

const SolutionListDesc = styled.p`
  font-size: 18px;
  font-weight: lighter;
  line-height: 1.22;
  color: #757575;
`;

const Divider = styled.div`
  max-width: ${p => p.width || '314px'};
  height: 2px;
  object-fit: contain;
  background-color: ${p => p.bgColor || '#0a7ef9'};
`;

const Solution = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);
  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  return (
    <Element name="Why Insent">
      <SolutionContainer>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
          onEnter={() => setContentIsVisible(true)}
          onLeave={() => setContentIsVisible(false)}
        >
          <Container>
             <SolutionPreHeader>Breakthrough with a <span>humanized approach</span> to conversational marketing</SolutionPreHeader>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <SolutionCard
                  style={leftCardStyleProps}
                  padding="95px 204px 95px 0"
                  bgColor="transparent"
                >
                  <SolutionHeader>Without Insent.ai</SolutionHeader>
                  <SolutionDesc>
                    Rely on homepage call-to-actions and static content offers.
                  </SolutionDesc>

                  <SolutionListTitle>Website status quo</SolutionListTitle>
                  <Divider bgColor="#64cffb" width="150px" />
                  <SolutionListDesc>
                  Continue spending more money to drive website traffic with the current conversion rate.
                  </SolutionListDesc>

                  <SolutionListTitle>Let your buyers leave</SolutionListTitle>
                  <Divider bgColor="#64cffb" width="170px" />
                  <SolutionListDesc>
                    Let your prospects bounce off your website before you can help them.
                  </SolutionListDesc>

                  <SolutionListTitle>Failed attempts to reaching accounts</SolutionListTitle>
                  <Divider bgColor="#64cffb" width="308px" />
                  <SolutionListDesc>
                    Once you’ve missed them on your website, continue reaching out via phone, email or LinkedIn.
                  </SolutionListDesc>
                </SolutionCard>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SolutionCard style={rightCardStyleProps}>
                  <SolutionHeader>With Insent.ai</SolutionHeader>
                  <SolutionDesc>Talk to your target accounts now, while you have their attention.</SolutionDesc>

                  <SolutionListTitle>Real-time, qualified notifications</SolutionListTitle>
                  <Divider width="316px" />
                  <SolutionListDesc>
                    Notify your sales team when prospects from your target accounts visit your website.
                  </SolutionListDesc>

                  <SolutionListTitle>
                    Know how to open a conversation
                  </SolutionListTitle>
                  <Divider width="404px" />
                  <SolutionListDesc>
                    Our integration with your MAP and CRM gives you an account view of their engagement data to craft the perfect outreach. 
                  </SolutionListDesc>

                  <SolutionListTitle>A smarter way to generate pipeline</SolutionListTitle>
                  <Divider width="310px" />
                  <SolutionListDesc>
                    Have an immediate impact on the metrics that matter: sales opportunities{' '}
                  </SolutionListDesc>
                </SolutionCard>
              </Grid>
            </Grid>
          </Container>
        </Waypoint>
      </SolutionContainer>
    </Element>
  );
};

Solution.propTypes = {};

export default Solution;
