import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import Header from './Header';
import CallToAction from 'ui/components/CallToAction';
import styled from 'styled-components';

import ArrowDown from 'resources/img/arrow-down.jpg';
import LeftLine from 'resources/img/leftLine.js';
import RightLine from 'resources/img/rightLine.js';

const HeroContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  text-align: center;
`;

const HeroHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  max-width: 726px;
  margin: 0 auto;
  padding-bottom: 26px;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 14px;
  }
`;

const HeroDesc = styled.p`
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  max-width: 532px;
  text-align: center;
  padding-bottom: 39px;
  margin: 0 auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  text-align: center;
`;

const ArrowDownWrapper = styled(animated.div)`
  width: 100%;
  position: absolute;
  bottom: 60px;
  right: 0;
  left: 0;
  height: 20px;
  width: 54px;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowDownIcon = styled.img`
  height: 16px;
  width: 50px;
`;

const LeftWrapper = styled(animated.div)`
  position: absolute;
  left: -100px;
  bottom: -100px;
  z-index: -1;

  svg {
    height: 500px;
    width: 700px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      height: 500px;
      width: 450px;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    }
  }
`;

const RightWrapper = styled(animated.div)`
  position: absolute;
  right: -60px;
  bottom: -100px;
  z-index: -1;

  svg {
    height: 700px;
    width: 500px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      height: 600px;
      width: 400px;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    }
  } 
`;

const HeroTabletDesktopView = props => {
  const arrowStyleProps = useSpring({
    delay: 500,
    from: { bottom: '60px' },
    to: async next => {
      while (1) {
        await next({ bottom: '80px' });
        await next({ bottom: '60px' });
      }
    },
  });

  const leftLineWrapperStyleProps = useSpring({
    delay: 500,
    from: { transform: 'translate3d(-500px,500px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  });

  const rightLineStyleProps = useSpring({
    delay: 500,
    from: { transform: 'translate3d(500px,500px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  });

  return (
    <TabletDesktop>
      <HeroContainer>
        <Header />
        <ArrowDownWrapper style={arrowStyleProps}>
          <ArrowDownIcon src={ArrowDown} alt="arrow-down" />
        </ArrowDownWrapper>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12}>
              <HeroHeader>
                Your <span>dream prospects</span> are on your website—and you don’t even know it.
              </HeroHeader>
              <HeroDesc>
                Insent is the only conversational marketing platform that instantly connects you
                to the buyers you worked so hard to attract.
              </HeroDesc>
              <CtaWrapper>
                <CallToAction />
              </CtaWrapper>
            </Grid>
          </Grid>
        </Container>
        <LeftWrapper style={leftLineWrapperStyleProps}>
          <LeftLine />
        </LeftWrapper>
        <RightWrapper style={rightLineStyleProps}>
          <RightLine />
        </RightWrapper>
      </HeroContainer>
    </TabletDesktop>
  );
};

const HeroMobileView = props => {
  const arrowStyleProps = useSpring({
    delay: 500,
    from: { bottom: '60px' },
    to: async next => {
      while (1) {
        await next({ bottom: '80px' });
        await next({ bottom: '60px' });
      }
    },
  });

  const leftLineWrapperStyleProps = useSpring({
    delay: 500,
    from: { transform: 'translate3d(-500px,500px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  });

  return (
    <Mobile>
      <HeroContainer>
        <Header />
        <ArrowDownWrapper style={arrowStyleProps}>
          <ArrowDownIcon src={ArrowDown} alt="arrow-down" />
        </ArrowDownWrapper>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12}>
              <HeroHeader>
                Your <span>dream prospects</span> are on your website—and you don’t even know it.
              </HeroHeader>
              <HeroDesc>
                Insent is the only conversational marketing platform that instantly connects you
                to the buyers you worked so hard to attract.
              </HeroDesc>
              <CtaWrapper>
                <CallToAction />
              </CtaWrapper>
            </Grid>
          </Grid>
        </Container>
        <LeftWrapper style={leftLineWrapperStyleProps}>
          <LeftLine />
        </LeftWrapper>
      </HeroContainer>
    </Mobile>
  );
};

const Hero = () => {
  return (
    <>
      <HeroTabletDesktopView />
      <HeroMobileView />
    </>
  );
};

Hero.propTypes = {};

export default Hero;
