import React from 'react';
import Footer from '../Home/Footer';
import Hero from './Hero.js';
import Header from '../Home/Header';
import Schedule from './Schedule.js';
import ConversationStarter from './ConversationStarter.js';
import LookAlike from './LookAlike.js';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    min-height: 6rem;
    background: ${p => p.theme.palette.blue.one};
`;
const ConverseContext = props => {
    return (
        <>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <Hero />
            <ConversationStarter />
            <LookAlike />
            <Schedule />
            <Footer />
        </>
    );
};

ConverseContext.propTypes = {};

export default ConverseContext;