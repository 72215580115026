import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import pabloChatting from 'resources/img/pabloChatting.png';
import chatImage from 'resources/img/readyToChat.png';
import chatBox from 'resources/img/chatBox.svg';
import barGraph from 'resources/img/barGraph.png';
import leftLine1 from 'resources/img/leftLine1.svg';
import Text from 'ui/components/Text';


const LeftLine1 = styled.img`
  position: absolute;
  top: -23rem;
  left: -33rem;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: none;
    }
`;
const Grid = styled(MuiGrid)`
    justify-content: center;
`;

const WHYFirstContent = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      flex-direction: column;

      img {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
        margin-top: 0px;
      }
    }
    img {
        height: 87px;
        width: 134px;
        margin-right: 10px;
    }

    p {
        font-size: 22px;
        letter-spacing: 0;
        color: #757575;
        opacity: 1;
        margin-top: 0;
        margin-bottom: 30px;
        margin-top: 30px;
    }
`;

const WHYContainer = styled(animated.div)`
    padding: 120px 1rem;
    text-align: center;
    vertical-align:middle;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      padding: 80px 1rem;
    }
`;





const WHYWrapper = styled.div`
  overflow: hidden;
  padding: 3rem 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const WHYContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }



  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;

    div > div {
      justify-content: left;
    }
  }
`;

const WHYHeader = styled.h2`
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 1rem;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
    max-width: unset;
    padding-top: 1rem;
  }
`;

const WHYDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    padding-bottom: 31px;
    font-weight: normal;
    text-align: left;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
  }
`;

const WHYAnimatedImg = styled(animated.img)`
  width: 510px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    width: calc(414px - 2rem);
  }
`;
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;


const WHYFooter = styled.div`
    padding: 92px 0;
    background: ${p => p.theme.palette.blue.one};

    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        padding: 92px 0 0;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        padding: 46px 0 0;
    }
`;

const SectionHeader = styled.h1`
  font-size: ${p => p.fontSize};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
padding-bottom: 10px;
padding-top: 10px;
  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
  }
`;

const SectionDescription = styled.p`
  font-size: 22px;
  line-height: 1.22;
  color: #757575;
  opacity: 0.8;
  max-width: 675px;
  text-align: center; 
  margin: auto auto 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;
const WHYFooterWrapper = styled.div`
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: relative;
  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    flex-direction: column;
  }
`;

const WHYFooterHeader = styled(SectionHeader)`
    max-width: 630px;
    float: left;
`;

const WHYFooterImage = styled.img`
    max-width: 541px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    height: 254px;
    width: 437px;
    position: absolute;
    right: 0;
    bottom: -110px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        position: relative;
        bottom: -19px
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        height: 188px;
        width: 300px;
    }

`;

const WHF = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);

  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
      onEnter={() => setContentIsVisible(true)}
      onLeave={() => setContentIsVisible(false)}
    >
      <div>
        <WHYContainer>
          <LeftLine1 src={leftLine1} />
          <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" paddingBottom="10px">So, why human-first?</Text>
          <Text fontSize="22px" smFontSize="16px" textAlign="center">Especially when you have chatbots to do the work.</Text>
          <WHYWrapper>
            <MuiContainer>
              <Grid container>
                <Grid md={6}>
                  <WHYAnimatedImg style={leftCardStyleProps} src={chatImage} alt="WHY" />
                </Grid>
                <Grid md={6}>
                  <WHYContentWrapper paddingLeft="3rem" style={rightCardStyleProps}>
                    <div>
                      <Text fontSize="18px" textAlign="left">
                        Unless your bots are foolproof, significantly engaged buyers who are ready to speak to you can experience frustration and friction on their path to purchase.
                                            <br />
                        <br />
                        And, well friction is a conversion-killer, an opportunity-staller, and an overall deal-breaker.
                                            <WHYFirstContent>
                          <img src={barGraph} />
                          <p>
                            According to PwC’s Future of Customer Experience Survey, 82% of US and 74% of non-US consumers want more human interaction even as technology improves.
                                              </p>
                        </WHYFirstContent>
                        Now imagine putting your prospects, customers, and the faces behind your business at the center of your marketing strategy for natural-born conversations and relationships. It can dramatically reduce the friction between you and your buyer.
                                            <br />
                        <br />
                        Now your buyer’s experience can bypass tedious email exchanges, long web forms, and constant chatbot pop-ups to a red-carpet, immediate connection to your sales team.
                                          </Text>
                    </div>
                  </WHYContentWrapper>
                </Grid>
              </Grid>
            </MuiContainer>
          </WHYWrapper>
          <WHYWrapper>
            <MuiContainer>
              <Grid container>
                <Grid md={6}>
                  <WHYContentWrapper paddingRight="3rem" style={leftCardStyleProps}>
                    <div>
                      <Text fontSize="22px" textAlign="left" fontWeight={700} marginBottom="30px" smMarginBottom="20px">
                        Your buyers are (kind of) in power.
                                          </Text>
                      <Text fontSize="18px" textAlign="left" >
                        For years, B2B marketers have functioned with the perspective of sellers. In fact, we’ve built marketing strategies, buyer journeys and sales processes all with that in mind. But what if we can shift that thinking and put our buyers first?
                                          </Text>
                      <Text fontSize="20px" textAlign="left" marginBottom="40px" marginTop="40px">
                        Gartner research discovered that when B2B buyers are considering a purchase, they spend only 17% of that time meeting with potential suppliers, of which only 5% is spent with any one sales rep.
                                          </Text>
                      <Text fontSize="18px" textAlign="left" >
                        That is astoundingly low, but not at all surprising.
                                            <br />
                        <br />
                        They’ve got the tools and resources to evaluate your product, so when you finally manage to get their attention—learn to keep it.
                                            <br />
                        <br />
                        They don’t need additional layers of friction to speak to a real person who can help accelerate their journey.
                                          </Text>
                    </div>
                  </WHYContentWrapper>
                </Grid>
                <Grid md={6}>
                  <WHYAnimatedImg style={rightCardStyleProps} src={chatBox} alt="WHY" />
                </Grid>
              </Grid>
            </MuiContainer>
          </WHYWrapper>
        </WHYContainer>
        <WHYFooter>
          <Container>
            <WHYFooterWrapper>
              <Text fontSize="40px" marginLeft="0" fontWeight={700} textAlign="left" paddingBottom="10px" maxWidth="630px">
                A <span>conversation</span> worth having is when the other person is listening.
              </Text>
              <WHYFooterImage src={pabloChatting} />
            </WHYFooterWrapper>
          </Container>
        </WHYFooter>
      </div>
    </Waypoint>
  );
};

WHF.propTypes = {};

export default WHF;
