import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import Carousel from 'nuka-carousel';
import { Container, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import useWindowSize from '@rehooks/window-size';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import theme from 'styles/theme';
import Dialog from '@material-ui/core/Dialog';
import CallToAction from 'ui/components/CallToAction';

import WhyImage from 'resources/img/pablo-sign-up.jpg';
import Back from 'resources/img/back';
import Next from 'resources/img/next';
import BgLine from 'resources/img/linesXC';
import TinySlider from "tiny-slider-react";
import './home.scss'
import Button from 'ui/components/Button';

const Grid = styled(({ padding, ...others }) => <MuiGrid {...others} />)`
  padding: ${p => p.padding};

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0px;
  }
`;

const WhyContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 144px 0;
  overflow: hidden;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 36px 0 144px 0;  }
`;

const WhyLineImgWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: -80px;
`;

const WhyContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 324px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    height: 100%;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    height: 100%;
  }
`;

const WhyHeader = styled.div`
  max-width: 474px;
  font-size: 25px;

  font-weight: ${p => p.bold && 'bold'};
  line-height: 1.2;
  color: ${p => p.color || '#191919'};
  padding-bottom: 17px;

  span {
    font-style: italic;
    font-weight: bold;
    color: #0a7ef9;
  }
`;

const DialogBody = styled.div`
  max-width: 382px;
  padding: 2rem;
`;

const CtaWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  text-align: center;
`;

const WhyDesc = styled.div`
  width: 331px;
  height: 40px;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;

  span {
    text-decoration: line-through;
  }
`;

const WhyImg = styled(animated.img)`
  width: 576px;
  height: 324px;
  object-fit: contain;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding-left: 200px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 0;
    width: calc(414px - 2rem);
    height: 266px;
    background-size: cover;
  }
`;

const CarouselCard = styled.div`
  margin: 4rem;
  display: flex;
  width: 675px;
  height: 386px;
  box-shadow: 5px 5px 60px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.16);
    margin: 2rem auto;
    width: 340px;
    height: 100%;
  }
`;

const CarouselLeftAppendix = styled.div`
  padding: 58px 0 0 40px;

  h2 {
    width: 316px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    color: #191919;
  }

  p {
    width: 316px;
    font-size: 18px;
    line-height: 1.22;
    color: #707070;
  }
`;

const CarouselRightAppendix = styled.div`
  margin-left: auto;
  background-image: url('${p => p.imgSrc}');
  background-repeat: no-repeat;
  background-size: cover;
  width: 266px;
  height: 386px;
`;

const CarouselTopAppendix = styled.div`
  background-image: url('${p => p.imgSrc}');
  background-repeat: no-repeat;
  background-size: cover;
  width: 340px;
  height: 190px;
`;

const CarouselBottomAppendix = styled.div`
  padding: 1rem;
  h2 {
    width: 316px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    color: #191919;
  }

  p {
    width: 316px;
    font-size: 18px;
    line-height: 1.22;
    color: #707070;
  }
`;

const NavButtonWrapper = styled.div`
  margin: 0 4rem;
  display: flex;
  position: absolute;
  top: 5rem;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin: 0 2rem;
  }
`;

const NavButton = styled.div`
  cursor: pointer;
  padding-right: 0.5rem;
  transition: transform 0.1s ease-in-out;
  will-change: transform;

  &:hover {
    transform: scale(1.2);
  }
`;

const SliderContainer = styled.div`
  & h1 {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    color: #0a7ef9;
  }
`;
const whyItems = [
  {
    img: require('resources/img/whyTwo.png'),
    title: 'Focus on connecting your prospects with people, not bots.',
    descOne:
      'Give your top-tiered accounts a human-led buying experience, from offline to online channels, including your website. Besides, conversations are better when it feels like the other person is listening.',
    descTwo:
      'With Insent.ai, you can prioritize your sales teams’ website visitor notifications for ideal prospects, and leverage bots as the back-up to set meetings while you’re busy, or out-of-office.',
  },
  {
    img: require('resources/img/whyOne.png'),
    title: 'Turn your website into your strongest sales channel',
    descOne:
      'All your marketing reports state it. Your website is your best source for opportunity creation, so why not double down on your marketing efforts there?',
    descTwo: 'Capture all your low-hanging fruit by talking to the buyers from your dream accounts while they are on your website.',
  },
  {
    img: require('resources/img/whyThree.jpg'),
    title: 'Enable sales to craft the perfect conversation starter',
    descOne:
      'Fuel your sales team’s live conversations with side-by-side views into engagement data from your enterprise CRM and Marketing Automation Platforms.',
    descTwo:
      'Insent provides your reps insight into account/prospect behavior, campaign membership, and recent engagement data to spark that compelling conversation.',
  },
];

const Why = props => {
  const [slidesToShow, setslidesToShow] = React.useState(1.9);
  const [isOpen, setIsOpen] = React.useState(false);

  let windowSize = useWindowSize();
  const { innerWidth } = windowSize;

  useEffect(() => {
    let slide = document.getElementsByClassName('tns-slide-active')
  }, [])



  React.useEffect(() => {
    const isTabletSize = innerWidth > theme.breakpoints.sm && innerWidth < theme.breakpoints.md;
    const isMobileSize = innerWidth < theme.breakpoints.sm;

    if (isTabletSize) {
      setslidesToShow(1);
    } else if (isMobileSize) {
      setslidesToShow(1);
    }
  }, [innerWidth]);

  const carouselSettings = {
    slidesToShow,
    slidesToScroll: 1,
  };

  const [isContentVisible, setContentIsVisible] = React.useState(false);
  const [isSliderVisible, setSliderIsVisible] = React.useState(false);

  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });
  const sliderStyleProps = useSpring({
    opacity: isSliderVisible ? 1 : 0,
    transform: isSliderVisible ? `translateX(0px)` : `translateX(400px)`,
  });

  const renderNavButtons = carouselProp => {
    return (
      <NavButtonWrapper>
        <NavButton onClick={carouselProp.previousSlide}>
          <Back height="30" width="30" />
        </NavButton>
        <NavButton onClick={carouselProp.nextSlide}>
          <Next height="30" width="30" />
        </NavButton>
      </NavButtonWrapper>
    );
  };

  const renderSlider = () => {
    return (
      <animated.div style={sliderStyleProps}>
        <TabletDesktop>
          <Carousel
            renderCenterLeftControls={props => null}
            renderCenterRightControls={props => null}
            renderBottomCenterControls={props => null}
            renderBottomLeftControls={props => renderNavButtons(props)}
            {...carouselSettings}
          >
            {whyItems.map((item, i) => {
              return (
                <CarouselCard key={i}>
                  <CarouselLeftAppendix>
                    <h2>{item.title}</h2>
                    <p>{item.descOne}</p>
                    <p>{item.descTwo}</p>
                  </CarouselLeftAppendix>
                  <CarouselRightAppendix imgSrc={item.img} />
                </CarouselCard>
              );
            })}
          </Carousel>
        </TabletDesktop>
        <Mobile>
          <Carousel
            renderCenterLeftControls={props => null}
            renderCenterRightControls={props => null}
            renderBottomCenterControls={props => null}
            renderBottomLeftControls={props => renderNavButtons(props)}
            {...carouselSettings}
          >
            {whyItems.map((item, i) => {
              return (
                <CarouselCard key={i}>
                  <CarouselTopAppendix imgSrc={item.img} />
                  <CarouselBottomAppendix>
                    <h2>{item.title}</h2>
                    <p>{item.descOne}</p>
                    <p>{item.descTwo}</p>
                  </CarouselBottomAppendix>
                </CarouselCard>
              );
            })}
          </Carousel>
        </Mobile>
      </animated.div>
    );
  };

  return (
    <Element name="Why Insent">
      <WhyContainer>
        <WhyLineImgWrapper>
          <BgLine height="1400px" width="1400px" />
        </WhyLineImgWrapper>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
          onEnter={() => setContentIsVisible(true)}
          onLeave={() => setContentIsVisible(false)}
        >
          <Container>
            <Grid padding="0 0 125px 0" container>
              <Grid item xs={12} lg={6}>
                <WhyContentWrapper style={leftCardStyleProps}>
                  <div>
                    <WhyHeader>
                      Insent was built to make generating pipeline <span>that</span> easy.
                    </WhyHeader>
                    <WhyDesc>Convert your target accounts into your dream customers today.</WhyDesc>
                  </div>
                </WhyContentWrapper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <WhyImg style={rightCardStyleProps} src={WhyImage} alt="whyImg" />
              </Grid>
            </Grid>
          </Container>
        </Waypoint>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          // bottomOffset="35%"
          onEnter={() => setSliderIsVisible(true)}
          onLeave={() => setSliderIsVisible(false)}
        >
          {renderSlider()}
        </Waypoint>
      </WhyContainer>
    </Element>
  );
};

Why.propTypes = {};

export default Why;
