import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { transparentize } from 'polished';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import HubspotForm from 'react-hubspot-form';

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const CallToActionContainer = styled.div`
  display: flex;
  margin: 0 auto;
  ${p => p.justify && `justify-content: ${p.justify};`}
  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    ${p => p.lgJustify && `justify-content: ${p.lgJustify};`}
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    ${p => p.mdJustify && `justify-content: ${p.mdJustify};`}
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    ${p => p.smJustify && `justify-content: ${p.smJustify};`}
  }
`;

const Input = styled(animated.input)`
  width: ${p => p.width || '313px'};
  height: 37px;
  padding: 0px 20px;
  font-size: 16px;
  border-radius: 24px;
  border-width: 0px;
  background-color: #e9ebf4;
  border-color: #e9ebf4;
  color: ${transparentize(0.46, '#21242c')};

  :focus {
    outline: 0;
    border-color: #0a7ef9;
  }
`;

const CallToAction = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let totalInputs = document.getElementsByClassName("hs-input");
    if (loaded) {
      if (totalInputs.length > 0) {
        for (let i = 0; i < totalInputs.length; i++) {
          totalInputs.item(i).setAttribute("placeholder", "Enter your email");
        }
      }
    }
  }, [loaded])

  return (
    <CallToActionContainer {...props}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        key={`home.success.snackbar`}
        open={open}
        onClose={() => setOpen(!open)}
        ContentProps={{
          'aria-describedby': 'home.success.snackbar',
        }}
        message={<span id="home.success.snackbar">We will be in touch with you soon!</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpen(!open)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <HubspotForm
        portalId='5712917'
        formId='ab291f10-fb2a-4996-bccc-92e0184548f0'
        placeholder="Enter you email"
        onSubmit={() => {
          window.history.pushState('form', 'Title', '/form-submitted');
          console.log('Submit!')}}
        onReady={(form) => setLoaded(true)}
        loading={<div>Loading...</div>}
      />
    </CallToActionContainer>
  );
};

CallToAction.propTypes = {};

export default CallToAction;
