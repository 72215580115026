import React from 'react';
import Footer from '../Home/Footer';
import Hero from './Hero.js';
import Header from '../Home/Header';
import Schedule from './Schedule.js';
import SalesPitch from './SalesPitch.js';
import HowItWorks from './HowItWorks.js';
import styled from 'styled-components';
import leftLine1 from 'resources/img/leftLine1.svg';
import rightLine1 from 'resources/img/rightLine1.svg';

const RightLine1 = styled.img`
    position: absolute;
    top: 100rem;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        display: none;
    }
`;

const LineContainer = styled.div`
    position: relative;
    overflow: hidden;
`;

const LeftLine1 = styled.img`
  position: absolute;
  top: 25rem;
    left: -33rem;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: none;
    }
`;

const HeaderWrapper = styled.div`
    min-height: 6rem;
    background: ${p => p.theme.palette.blue.one};
`;
const QualifyEngage = props => {
    return (
        <>  
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <Hero />
            <LineContainer>
                <LeftLine1 src={leftLine1} />
                <RightLine1 src={rightLine1} />
                <SalesPitch />
                <HowItWorks />
            </LineContainer>
            <Schedule />
            <Footer />
        </>
    );
};

QualifyEngage.propTypes = {};

export default QualifyEngage;