import React from 'react';
import Footer from '../Home/Footer';
import Hero from './Hero.js';
import Header from '../Home/Header';
import Schedule from './Schedule.js';
import Summarize from './Summarize.js';
import ChatBot from './ChatBot.js';
import WhyConvoMarketing from './WhyConvoMarketing.js';
import WhyHumanFirstSection from './WhyHumanFirst.js';
import LiveChat from './LiveChat.js';
import styled from 'styled-components';

import rightLine1 from 'resources/img/rightLine1.svg';

const RightLine1 = styled.img`
    position: absolute;
    top: 30vh;
    width: 88%;
    right: -30rem;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        display: none;
    }
`;

const HeaderWrapper = styled.div`
    min-height: 6rem;
    background: ${p => p.theme.palette.blue.one};
`;

const WHFContainer = styled.div`
    position: relative;
    overflow: hidden;
`;



const WhyHumanFirst = props => {
    return (
        <WHFContainer>
            <RightLine1 src={rightLine1} />
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <Hero />
            <WhyConvoMarketing />
            <WhyHumanFirstSection />
            <LiveChat />
            <ChatBot />
            <Summarize />
            <Schedule /> 
            <Footer />
        </WHFContainer>
    );
};

WhyHumanFirst.propTypes = {};

export default WhyHumanFirst;