import React from 'react';
import Hero from './Hero';
import Footer from '../Home/Footer';

const Home = props => {
  return (
    <>
      <Hero />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
