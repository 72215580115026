import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import GlobalStyle from 'styles';

import Home from './Home';
import Marketing from './Marketing';
import Sales from './Sales';
import PrivacyPolicy from './PrivacyPolicy';
import GDPR from './GDPR';
import ABM from './ABM';
import QualifyEngage from './QualifyEngage';
import ConverseContext from './ConverseContext';
import WhyHumanFirst from './WhyHumanFirst';
import Login from './Login';

const App = props => {
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/gdpr" component={GDPR} />
        <Route exact path="/marketing-team" component={Marketing} />
        <Route exact path="/sales-team" component={Sales} />
        <Route exact path="/ABM" component={ABM} />
        <Route exact path="/qualify-engage" component={QualifyEngage} />
        <Route exact path="/converse-context" component={ConverseContext} />
        <Route exact path="/why-human-first" component={WhyHumanFirst} />
        <Route exact path="/signin" component={Login} />
        <Route path="*" />
      </BrowserRouter>
      <GlobalStyle />
    </div>
  );
};

App.propTypes = {};

export default App;
