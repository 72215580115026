import React from 'react';

const LinesXC = props => (
  <svg viewBox="0 0 1380.615 1374.849" {...props}>
    <g fill="none">
      <path data-name="Line 18" stroke="#62ccf7" opacity={0.5} d="M.288 430.471l412-290" />
      <path data-name="Line 19" stroke="#63cdf8" opacity={0.5} d="M412.288 140.471l297 202" />
      <path data-name="Line 20" stroke="#64cffb" opacity={0.502} d="M709.288 342.471l-638 507" />
      <path data-name="Line 21" stroke="#64cffb" opacity={0.5} d="M55.288 537.471l719-537" />
      <path data-name="Line 22" stroke="#64cffb" opacity={0.5} d="M774.288.471l606 215" />
      <path data-name="Line 23" stroke="#64cffb" opacity={0.5} d="M1380.288 215.471l-1339 1159" />
    </g>
  </svg>
);

export default LinesXC;
