import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import howItWorks1 from 'resources/img/howItWork1.png';
import howItWorks2 from 'resources/img/howItWork2.png';
import howItWorks3 from 'resources/img/howItWork3.png';
import howItWorks4 from 'resources/img/howItWork4.png';
import Text from 'ui/components/Text';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const Grid = styled(MuiGrid)`
    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        justify-content: center;
    }
`;

const HowItWorksContainer = styled(animated.div)`
    padding: 68px 1rem 100px;
    text-align: center;
    vertical-align:middle;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      padding: 68px 1rem 100px;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    } 
`;

const HowItWorksWrapper = styled.div`
  overflow: hidden;
  padding: 3rem 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const HowItWorksContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
    &&& {
      ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
      ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      &&& {
        padding: 0
      }
    }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
&&& {
      padding: 0;
    }
    div > div {
      justify-content: left;
    }
  }
`;


const HowItWorksDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    font-weight: normal;
    text-align: left;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;

  }
`;

const HowItWorksAnimatedImg = styled(animated.img)`
width: 100%;
    max-width: 541px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    padding: 2rem 0;
  }
`;

const HowItWorks = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);

  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });


  return (
    // <Element name="Qualify-Engage-Desktop">
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
      onEnter={() => setContentIsVisible(true)}
      onLeave={() => setContentIsVisible(false)}
    >

      <HowItWorksContainer>
        <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center">
         So, how does this work?
        </Text>
        <HowItWorksWrapper>
          <Container>
            <Grid container>
              <Grid lg={6}>
                <HowItWorksAnimatedImg style={leftCardStyleProps} src={howItWorks1} alt="HowItWorks" />
              </Grid>
              <Grid lg={6}>
                <HowItWorksContentWrapper paddingRight="3rem" style={rightCardStyleProps}>
                  <div>
                    <HowItWorksDesc>
                      You will build the demographic and behavioral criteria, similar to your lead scoring methodology, on what qualifies a lead to be ready for sales.
                                                <br />
                      <br />
                      Think of this as your MQL criteria. When is your prospect ready to talk?
                                            </HowItWorksDesc>
                  </div>
                </HowItWorksContentWrapper>
              </Grid>
            </Grid>
          </Container>

        </HowItWorksWrapper>
        <HowItWorksWrapper>
          <Container>

            <Grid container>


              <Grid md={6}>
                <HowItWorksContentWrapper paddingLeft="3rem" style={leftCardStyleProps}>
                  <div>
                    <HowItWorksDesc>
                      Now when that highly engaged person returns to your website, Insent will leverage the integration with your marketing and sales technology stack to identify them in real-time.
                                    </HowItWorksDesc>
                  </div>
                </HowItWorksContentWrapper>
              </Grid>
              <Grid md={6}>
                <HowItWorksAnimatedImg style={rightCardStyleProps} src={howItWorks2} alt="HowItWorks" />
              </Grid>
            </Grid>
          </Container>

        </HowItWorksWrapper>
        <HowItWorksWrapper>
          <Container>

            <Grid container>

              <Grid md={6}>
                <HowItWorksAnimatedImg style={leftCardStyleProps} src={howItWorks3} alt="HowItWorks" />
              </Grid>
              <Grid md={6}>
                <HowItWorksContentWrapper paddingRight="3rem" style={rightCardStyleProps}>
                  <div>
                    <HowItWorksDesc>
                      Within seconds, your lead is qualified and Insent alerts your sales reps to open a one-on-one channel to engage them on-site.
                                                </HowItWorksDesc>
                  </div>
                </HowItWorksContentWrapper>
              </Grid>
            </Grid>
          </Container>
        </HowItWorksWrapper>
        <HowItWorksWrapper>
          <Container>

            <Grid container>


              <Grid md={6}>
                <HowItWorksContentWrapper paddingLeft="3rem" style={leftCardStyleProps}>
                  <div>
                    <HowItWorksDesc>
                      Your sales reps then strike up a conversation with their qualified prospects right then and there.
                    </HowItWorksDesc>
                  </div>
                </HowItWorksContentWrapper>
              </Grid>
              <Grid md={6}>
                <HowItWorksAnimatedImg style={rightCardStyleProps} src={howItWorks4} alt="HowItWorks" />
              </Grid>
            </Grid>
          </Container>

        </HowItWorksWrapper>
      </HowItWorksContainer>
    </Waypoint>
    // </Element>
  );
};

HowItWorks.propTypes = {};

export default HowItWorks;